import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

// Component
import { TabViewComponent } from "src/components/tab_view_component";

// Containers
import { WoundAssessmentProgressListContainer } from "src/containers/wound_assessment_progress_list_container";

// Analytics
import { track } from "src/analytics";
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { LoadingComponent } from "src/components/loading_component";
import { LoadingErrorComponent } from "src/components/loading_error_component";

// Translations
import i18n from "src/translations";

import { loadStates as _loadStates } from "src/constants/load_states";
import { analyticsEventNames } from "src/constants";

/**
 * Component that renders a tab view for selecting and displaying a wound list
 * filtered by wound progress
 */
export class WoundAssessmentProgressTabViewComponent extends React.PureComponent {
  static propTypes = {
    progressValues: PropTypes.array.isRequired,
    selectedProgressFilter: PropTypes.string.isRequired,
    sendSetSelectedProgressFilterAction: PropTypes.func.isRequired,
    assessmentVersion: PropTypes.string.isRequired,
    woundOverallProgressCounts: PropTypes.object.isRequired
  };

  onTabSelected = tabValue => {
    track("WOUND_PROGRESS_TAB_SELECTED", { progress: tabValue });
    this.props.sendSetSelectedProgressFilterAction(tabValue);
  };

  /**
   * Build configuration for the tab view.
   * @return {Array} array of tab configuration objects for the TabViewComponent
   */
  getTabConfig() {
    const {
      progressValues,
      assessmentVersion,
      woundOverallProgressCounts,
      selectedProgressFilter
    } = this.props;
    return progressValues.map(progress => {
      return {
        value: progress,
        count: get(
          woundOverallProgressCounts,
          `${selectedProgressFilter}.${progress}`,
          0
        ),
        title: i18n.t(`data.overallProgress.${assessmentVersion}.${progress}`)
      };
    });
  }

  render() {
    const loadStates = Object.values(this.props.firstPageContextsLoadStates);

    if (loadStates.every(val => !val)) {
      return (
        <StartRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsWoundTabsList}
        >
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (loadStates.some(val => val == _loadStates.loading)) {
      return (
        <StartRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsWoundTabsList}
        >
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (loadStates.some(val => val == _loadStates.error)) {
      return (
        <FinishRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsWoundTabsList}
          error={true}
        >
          <LoadingErrorComponent message="Unable to load information for all locations. Select individual locations or contact Customer Support for a detailed report for your organization." />
        </FinishRenderTimeTrackComponent>
      );
    } else {
      return (
        <FinishRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsWoundTabsList}
        >
          <TabViewComponent
            tabs={this.getTabConfig()}
            selectedTabValue={this.props.selectedProgressFilter}
            selectTab={this.onTabSelected}
            tabHeaderType="count_title"
          >
            <WoundAssessmentProgressListContainer />
          </TabViewComponent>
        </FinishRenderTimeTrackComponent>
      );
    }
  }
}

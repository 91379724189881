import axios from "axios";
import { registerForCancellation } from "../../../cancellation";

/**
 * Fetch wound assessments
 * @param {Object} payload the query parameters
 * wounds
 * location_id
 * start_time
 * end_time
 * acquired
 * wound_overall_progress
 * @return {Promise} Promise with the response
 */
export async function fetchWoundAssessments(payload) {
  const progressFilter = payload.filter.wound_overall_progress;
  // Register for cancellation
  // NOTE: because this endpoint is called with different progressFilters set
  // we need a custom cancelation id to parse them out
  const config = {
    cancelToken: registerForCancellation(
      `api-v2-assessments/${progressFilter}`
    ),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };

  // Send POST request
  const url = "/api/v4/api-v2-assessments";
  return await axios.post(url, payload, config);
}
